/* eslint-disable max-classes-per-file */
import { ITreeOption } from '@e-bilet/ui-tree-select';
import { Category } from '../../../../apps/portal/src/app/rest-api/models/category.model';
import { Province } from '../../../../apps/portal/src/app/rest-api/models/province.model';
import { TitleEvent } from '../../../../apps/portal/src/app/rest-api/models/title-page.model';
import { ISearchPageQueryParams } from '../../../../apps/portal/src/app/search-page/search-page-query-params.interface';
import { GtmVariableNames } from './models/gtm-variable-names.enum';

export interface IVirtualPageView {
    event: GtmVariableNames.VirtualPageView;
    vPagePath: string;
    vPageTitle: 'kup_bilet';
}

export abstract class BaseVirtualPageView {
    abstract getParams(titleEvent: TitleEvent): IVirtualPageView;
}

export abstract class SimpleVirtualPageView extends BaseVirtualPageView {
    abstract basePagePath: string;
    item: string;

    constructor(item: string | undefined) {
        super();
        this.item = item ?? '';
    }

    getParams(titleEvent: TitleEvent): IVirtualPageView {
        return {
            event: GtmVariableNames.VirtualPageView,
            vPagePath: `${this.basePagePath}${this.item}`,
            vPageTitle: 'kup_bilet',
        };
    }
}

export class ArtistVirtualPageView extends SimpleVirtualPageView {
    basePagePath = '/krok1.2/kup-bilet/artysta/';
}

export class OrganizerVirtualPageView extends SimpleVirtualPageView {
    basePagePath = '/krok1.3/kup-bilet/organizator/';
}

export class SearchInputVirtualPageView extends SimpleVirtualPageView {
    basePagePath = '/krok1.5/kup-bilet/podpowiedzi_';
}

export class LandingPageVirtualPageView extends SimpleVirtualPageView {
    basePagePath = '/krok1.6/kup-bilet/lp/';
}

export class TitleVirtualPageView extends BaseVirtualPageView {
    category: string;
    subcategory: string;
    title: string;

    constructor(category: string, subcategory: string, title: string) {
        super();
        this.category = category;
        this.subcategory = subcategory;
        this.title = title;
    }

    getParams(titleEvent: TitleEvent): IVirtualPageView {
        return {
            event: GtmVariableNames.VirtualPageView,
            vPagePath: `/krok1.1/kup-bilet/${this.category}_${this.subcategory}_${encodeURIComponent(
                titleEvent.city,
            )}/${this.title}`,
            vPageTitle: 'kup_bilet',
        };
    }
}

export class SearchPageVirtualPageView extends BaseVirtualPageView {
    location: string;
    date: string;
    categorySubcategory: string;

    constructor(
        queryParams: ISearchPageQueryParams,
        location: ITreeOption<Province> | null,
        category: ITreeOption<Category> | null,
    ) {
        super();

        const dates = [queryParams.dateFrom?.split('T')[0], queryParams.dateTo?.split('T')[0]].filter((date) => !!date);

        if (!dates.length) {
            dates.push('none');
        }

        this.date = dates.join(',');

        if (location) {
            this.location = `${location.parent?.value?.slug},${location.value?.slug}`;
        } else {
            this.location = 'none';
        }

        if (category) {
            if (category.parent) {
                this.categorySubcategory = `${category.parent.value.slug},${category.value.slug}`;
            } else {
                this.categorySubcategory = category.value.slug;
            }
        } else {
            this.categorySubcategory = 'none';
        }
    }

    getParams(titleEvent: TitleEvent): IVirtualPageView {
        return {
            event: GtmVariableNames.VirtualPageView,
            vPagePath: `/krok1.4/kup-bilet/wyszukiwarka_${this.location}_${this.date}_${this.categorySubcategory}`,
            vPageTitle: 'kup_bilet',
        };
    }
}
